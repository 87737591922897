.root-web{
  font-family: 'Poppins', sans-serif;
  padding-top: 4rem; 
}
h1, h2, h3{
  color: #2b2b2b;
}
p{
  color: #55585a;
}

@media (min-width: 40rem){
  .root-web{
    padding-top: 5rem;
  }
}