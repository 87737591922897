.about-landing{
    position: relative;
    height: 500px;
    background: linear-gradient(0deg, #242424 0%, rgba(0, 0, 0, 0) 60%), url("https://res.cloudinary.com/ds5l1k3bl/image/upload/v1640905751/DSC_9930_kmribt.jpg");
    background-size: cover; 
    background-position-x: center;
    display: flex;
    flex-direction: column-reverse;
    padding: 0rem 1rem;
}
.about-landing p{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    width: 300px;
    text-align: center;
}
.about-landing span{
    color: #2F54EB;
}
.about-us-text-wrapper{
    padding: 0rem 1rem;
    margin-top: 50px;
}
.about-us-text-wrapper p{
    font-size: 16px !important;
}
.about-mission-vision{
    margin: 80px 0px;
    background: #F5F5FF;
    padding: 4rem 1rem;
    font-size: 16px !important;
}
.about-mission-vision img{
    width: 200px;
}
.about-mission,
.about-vision{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-direction: column-reverse;
}
.about-vision{
    text-align: end;
}
.about-mission-vision-description{
    display: flex;
    align-items: flex-start;
}
.about-vision .about-mission-vision-description{
    flex-direction: row-reverse;
}
.about-mission-vision-description-bar{
    width: 70px;
    height: 5px;
    background: #2F54EB;
    margin: 5px;
    border-radius: 8px;
}
.about-mission-vision p{
    font-size: 14px;
    margin: 0;
    padding: 0;
}

@media (min-width: 60rem){
    .about-us-text-wrapper{
        padding: 0rem 4rem;
        margin-top: 80px;
    }
    .about-us-text-wrapper p{
        font-size: 18px;
    }
    .about-mission{
        flex-direction: row;
    }
    .about-vision{
        flex-direction: row-reverse;
    }
    .about-mission-vision p{
        width: 500px;
    }
    
}