.Sidebar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}
.Sidebar-body{
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20rem;
    width: 15rem;
    background: #fff;
    padding: 0rem 2rem;
    transition: all .3s ease-in-out;
}
.show-sidebar{
    left: 0;
}
.web_sidebar-header{
    display: flex;
    justify-content: space-between;
    height: 4rem;
    align-items: center;
}
.web_sidebar_menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.sidebar-nav-list{
    padding-top: 20px;
}
.sidebar-nav-list .web_nav-list_item{
    margin: 0 0 30px 0;
    font-weight: 500;
}
.sidebar-nav-list .web_nav-list_item:hover{
    font-weight: 600;
}
.sidebar-nav-list .webactive{
    font-weight: 600;
    border-bottom: none;
}

@media (min-width: 40rem){
    .Sidebar-body{
        width: 20rem;
        left: -25rem;
    }
    .show-sidebar{
        left: 0;
    }
    .web_sidebar-header{
        height: 5rem;
    }
    .web_sidebar_menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

@media (min-width: 60rem){
    .Sidebar{
        display: none;
    }
}